// extracted by mini-css-extract-plugin
export var cartMobile = "_cart-module--cartMobile--xsD9h";
export var cartMobileBrd = "_cart-module--cartMobileBrd--0hcvO";
export var cartTotalMobile = "_cart-module--cartTotalMobile--XKqR-";
export var notes = "_cart-module--notes--NACYh";
export var cart = "_cart-module--cart--lGR5b";
export var countUpBtn = "_cart-module--countUpBtn--WpBwL";
export var countDownBtn = "_cart-module--countDownBtn--ak49E";
export var textCenter = "_cart-module--textCenter--dWGPc";
export var numCell = "_cart-module--numCell--mygUy";
export var clearBtn = "_cart-module--clearBtn--NeS7W";
export var total = "_cart-module--total--PoJtq";
export var totalPrice = "_cart-module--totalPrice--g3vcZ";
export var guideWrapper = "_cart-module--guideWrapper--ltNLb";
export var guideSection = "_cart-module--guideSection--Qtt0X";
export var steps = "_cart-module--steps--0b8ZB";
export var bankInfo = "_cart-module--bankInfo--x9wmt";
export var cartImg = "_cart-module--cartImg--KrPxU";
export var cellTtl = "_cart-module--cellTtl--fllwV";
export var countBtnWrapper = "_cart-module--countBtnWrapper--7wG2S";
export var countNum = "_cart-module--countNum--FeOWu";
export var contactInfo = "_cart-module--contactInfo--zJKdL";