import * as React from "react"
import { useState, useEffect } from 'react'

import Layout from "src/components/layout"
import Seo from "src/components/seo"

import * as styles from "src/scss/_shop.module.scss"
import * as cartStyles from "src/scss/_cart.module.scss"

import Printer from "src/images/info-invoice/PLQ-50S_left.jpg"
import Invoice from "src/images/info-invoice/invoice-product.png"

export default function Cart () {
  const [count, setCount] = useState(0); // 伝票プリンターセット合計個数
  const [printerTotalPrice, setPrinterTotalPrice] = useState(0); // 伝伝票プリンターセット合計個数

  const [count2, setCount2] = useState(0); // 伝票のみ合計個数
  const [denpyoTotalPrice, setDenpyoTotalPrice] = useState(0); // 伝票のみ合計個数

  const [totalPrice, setTotalPrice] = useState(0); // 全体合計金額
  const [totalQuantity, setTotalQuantity] = useState(0); // 全体合計数量

  useEffect(() => {
    const printerNum = Number(localStorage.getItem("PrinterSetQuantity"));
    const denpyoNum = Number(localStorage.getItem("denpyoOnlyQuantity"));

    if (printerNum !== null) {
      setCount(printerNum);
    }
    if (denpyoNum !== null) {
      setCount2(denpyoNum);
    }
    updatePrices()
  }, [count, count2]);

  const updatePrices = () => { // それぞれの合計金額
    const printerNum = Number(localStorage.getItem("PrinterSetQuantity"));
    const denpyoNum = Number(localStorage.getItem("denpyoOnlyQuantity"));

    if (printerNum !== null) {
      const totalPrinterPrice = 141900 * printerNum
      const localString = totalPrinterPrice.toLocaleString();
      setPrinterTotalPrice(localString);
    } else {
      setPrinterTotalPrice(0);
    }
    if (denpyoNum !== null) {
      const totalDenpyoPrice = 13750 * denpyoNum
      const localString = totalDenpyoPrice.toLocaleString();
      setDenpyoTotalPrice(localString);
    } else {
      setDenpyoTotalPrice(0);
    }
    updateTotalQuantity()
  }

  const updateTotalQuantity = () => { // 全ての合計個数
    const printerNum = Number(localStorage.getItem("PrinterSetQuantity"))
    const denpyoNum = Number(localStorage.getItem("denpyoOnlyQuantity"))
    const preQuantity = printerNum + denpyoNum
    setTotalQuantity(preQuantity)
    updateTotalPrice()
  }

  const updateTotalPrice = () => { // 全ての合計金額
    const printerNum = Number(localStorage.getItem("PrinterSetQuantity"))
    const denpyoNum = Number(localStorage.getItem("denpyoOnlyQuantity"))

    const preTotal = (141900 * printerNum) + (13750 * denpyoNum)
    const localString = preTotal.toLocaleString();
    setTotalPrice(localString)
  }

  const increase = () => { // 伝票プリンターセットの数量追加
    const printerNum = Number(localStorage.getItem("PrinterSetQuantity"));
    if (printerNum < 100) {
      const addPrinter = printerNum + 1
      localStorage.setItem("PrinterSetQuantity", addPrinter)
      setCount(addPrinter)
    }
  }

  const decrease = () => { // 伝票プリンターセットの数量減らす
    const printerNum = Number(localStorage.getItem("PrinterSetQuantity"));
    if (printerNum > 1) {
      const deductPrinter = printerNum - 1
      localStorage.setItem("PrinterSetQuantity", deductPrinter)
      setCount(deductPrinter)
    }
  }

  const increase2 = () => { // 伝票の数量追加
    const denpyoNum = Number(localStorage.getItem("denpyoOnlyQuantity"))
    if (denpyoNum < 100) {
      const addDenpyo = denpyoNum + 1
      localStorage.setItem("denpyoOnlyQuantity", addDenpyo)
      setCount2(addDenpyo)
    }
  }

  const decrease2 = () => { // 伝票の数量減らす
    const denpyoNum = Number(localStorage.getItem("denpyoOnlyQuantity"))
    if (denpyoNum > 1) {
      const deductDenpyo = denpyoNum - 1
      localStorage.setItem("denpyoOnlyQuantity", deductDenpyo)
      setCount2(deductDenpyo)
    }
  }

  const clearPrinterSet = () => { // 伝票プリンターセットの削除
    const result = window.confirm("インフォ・ザ・伝票 ＆ PLQ-50S（プリンター）セットをカートから削除します。よろしいですか？");
    if (result) {
      localStorage.removeItem('PrinterSetQuantity');
      setCount(0)
    }
  }

  const clearDenpyoOnly = () => { // 伝票の削除
    const result = window.confirm("インフォ・ザ・伝票をカートから削除します。よろしいですか？");
    if (result) {
      localStorage.removeItem('denpyoOnlyQuantity');
      setCount2(0)
    }
  }

  const sendData = () => { // 確認画面に移動
    const printerNum = Number(localStorage.getItem("PrinterSetQuantity"));
    const denpyoNum = Number(localStorage.getItem("denpyoOnlyQuantity"));
    const totalPrinterPrice = 141900 * printerNum;
    const totalDenpyoPrice = 13750 * denpyoNum;
    const totalP = totalPrinterPrice + totalDenpyoPrice
    const orderInfo = {
      "printerQuantity": {count},
      "printerPrice": {totalPrinterPrice},
      "denpyoQuantity": {count2},
      "denpyoPrice": {totalDenpyoPrice},
      "totalPrice": totalP
    }
    localStorage.setItem("orderInfoObj", JSON.stringify(orderInfo));
    window.location.href = '/online-store/customer-form/';
  }

  return (
    <Layout>
      <Seo title="購入ページ" />
      <div className={styles.shopHeader}>
        <h1>ReNK CHANNEL オンラインショッピング</h1>
      </div>
      <div className="pageWrapper">

        <h2 className={styles.shopPageTtl}>購入ページ</h2>

        <p>現在のカートの中身</p>
        {count === 0 && count2 === 0 &&
          <p>カートの中身はありません。</p>
        }

        {/* スマホの時のテーブル */}
        {count2 !== 0 &&
          <div className={cartStyles.cartMobile}>
            <div className={cartStyles.cartImg}>
              <img src={ Invoice } alt="" />
            </div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <span className={cartStyles.cellTtl}>商品名称：</span>
                    インフォ・ザ・伝票
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={cartStyles.cellTtl}>単価：</span>
                    ¥ 13,750
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className={cartStyles.countBtnWrapper}>
                      <span className={cartStyles.cellTtl}>数量：</span>
                      <button className={cartStyles.countDownBtn} onClick={decrease2}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#414141"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg>
                      </button>
                      <span className={cartStyles.countNum} >
                        {count2}
                      </span>
                      <button className={cartStyles.countUpBtn} onClick={increase2}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#414141"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className={cartStyles.numCell}>
                    <span className={cartStyles.cellTtl}>金額（税込）：</span>
                    ¥ {denpyoTotalPrice}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={cartStyles.cellTtl}>削除：</span>
                    <button className={cartStyles.clearBtn} onClick={clearDenpyoOnly}>削除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }

                {count2 !== 0 && count !== 0 &&
          <hr className={cartStyles.cartMobileBrd} />
        }

        {count !== 0 &&
          <div className={cartStyles.cartMobile}>
            <div className={cartStyles.cartImg}>
              <img src={ Printer } alt="" />
            </div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <span className={cartStyles.cellTtl}>商品名称：</span>
                    インフォ・ザ・伝票 ＆ PLQ-50S（プリンター）セット
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={cartStyles.cellTtl}>単価：</span>
                    ¥ 141,900
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <div className={cartStyles.countBtnWrapper}>
                      <span className={cartStyles.cellTtl}>数量：</span>
                      <button className={cartStyles.countDownBtn} onClick={decrease}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#414141"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg>
                      </button>
                      <span className={cartStyles.countNum} >
                        {count}
                      </span>
                      <button className={cartStyles.countUpBtn} onClick={increase}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#414141"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className={cartStyles.numCell}>
                    <span className={cartStyles.cellTtl}>金額（税込）：</span>
                    ¥ {printerTotalPrice}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className={cartStyles.cellTtl}>削除：</span>
                    <button className={cartStyles.clearBtn} onClick={clearPrinterSet}>削除</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        }

        <hr className={cartStyles.cartMobileBrd} />

        {/* ウェブの時のテーブル */}
        <div className={cartStyles.cartTotalMobile}>
          <table>
            <tbody>
              <tr>
                <td>合計</td>
              </tr>
              <tr>
                <td>数量：{totalQuantity}</td>
              </tr>
              <tr>
                <td>金額（税込）：{totalPrice}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* ウェブの時のテーブル */}
        <div className={cartStyles.cart}>
          <table>
            <thead>
              <tr>
                <td>商品名称</td>
                <td>単価</td>
                <td>数量</td>
                <td>金額（税込）</td>
                <td>削除</td>
              </tr>
            </thead>
            <tbody>
              {count2 !== 0 &&
                <tr>
                  <td>インフォ・ザ・伝票</td>
                  <td className={cartStyles.numCell}>13,750</td>
                  <td className={cartStyles.textCenter}>
                    <button className={cartStyles.countDownBtn} onClick={decrease2}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#414141"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg>
                    </button>
                    {count2}
                    <button className={cartStyles.countUpBtn} onClick={increase2}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#414141"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                    </button>
                  </td>
                  <td className={cartStyles.numCell}>{denpyoTotalPrice}</td>
                  <td className={cartStyles.textCenter}><button className={cartStyles.clearBtn} onClick={clearDenpyoOnly}>削除</button></td>
                </tr>
              }
              {count !== 0 &&
                <tr>
                  <td>インフォ・ザ・伝票 ＆ PLQ-50S（プリンター）セット</td>
                  <td className={cartStyles.numCell}>141,900</td>
                  <td className={cartStyles.textCenter}>
                    <button className={cartStyles.countDownBtn} onClick={decrease}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#414141"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13H5v-2h14v2z"/></svg>
                    </button>
                    {count}
                    <button className={cartStyles.countUpBtn} onClick={increase}>
                      <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#414141"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/></svg>
                    </button>
                  </td>
                  <td className={cartStyles.numCell}>{printerTotalPrice}</td>
                  <td className={cartStyles.textCenter}><button className={cartStyles.clearBtn} onClick={clearPrinterSet}>削除</button></td>
                </tr>
              }
            </tbody>
            <tfoot>
              <tr>
                <td className={cartStyles.numCell} colSpan="2">合計</td>
                <td className={cartStyles.textCenter}>{totalQuantity}</td>
                <td className={cartStyles.numCell}>{totalPrice}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div className={cartStyles.guideWrapper}>
          <h2 className={styles.shopPageTtl}>ご購入にあたっての注意事項</h2>

          <section className={cartStyles.guideSection}>
            <h3>購入までの流れ</h3>
            <ul className={cartStyles.steps}>
              <li><span>１</span>商品をかごに入れる</li>
              <li><span>２</span>お客様情報の登録</li>
              <li><span>３</span>注文内容・登録情報の確認</li>
              <li><span>４</span>注文確定/商品の発送</li>
            </ul>
          </section>

          <section className={cartStyles.guideSection}>
            <h3>キャンセルについて</h3>
            <p>
              何らかの事情によりご注文をキャンセルなさりたい場合は、お送りしたご注文確認メールの内容をコピーして、キャンセルする旨をご記入のメールを弊社までお送り下さい。
              <br />
              発送済みの場合はキャンセルはできません。下記の返品の手続きをお取り下さい。
            </p>
          </section>

          <section className={cartStyles.guideSection}>
            <h3>お支払い・料金について</h3>
            <p>
              リンクチャネルオンラインショッピングでは、銀行振込のみお取り扱いしております。ご注文日から7日以内に下記口座へ代金をお振り込み下さい。<br />
              7日以内にお振り込みがない場合はご注文を取り消させていただきます。お振り込み確認後、商品を手配いたします。<br />
              なお、振込手数料につきましては、お客様のご負担にてお願いいたします。
            </p>

            <ul>
              <li>［ 振込先 ］</li>
              <li>第四北越銀行十日町中央支店 普通1348584 </li>
              <li>リンクチャネル(株)</li>
            </ul>

            <table className={cartStyles.bankInfo}>
              <thead>
                <tr>
                  <td>送料</td>
                  <td>
                    商品価格には商品の送料が含まれています。(日本国内のみ)
                    別途送料をお支払いいただく必要はございません。
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>納期</td>
                  <td>
                    代金のお振り込み確認後10営業日以内に発送いたします。
                    発送時にメールで発送のご連絡をいたします。<br />
                    なお、機材の入荷状況により前後する場合もございます。その際は別途お知らせ致します。
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          <section className={cartStyles.guideSection}>
            <h3>返品について</h3>
            <p>
              返品は未開封品のみ承ります。返品は商品の到着後7日以内にお願いいたします。事前にメールまたは電話でご連絡下さい。<br />
              返品商品を確認後7日以内に振込手数料を差し引いた代金をお客様指定口座に振り込ませていただきます。
            </p>
            <p className={cartStyles.notes}>※ 返品送料、返金の振込手数料はお客様ご負担とさせていただきます。</p>
          </section>
        </div>

        <div className={styles.shopBtnWrapper}>
          <div className={styles.backBtn}>
            <a href="/online-store/">
              <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 30 30" width="30px"><rect fill="none" height="30" width="30"/><path d="M9,19l1.41-1.41L5.83,13H22V11H5.83l4.59-4.59L9,5l-7,7L9,19z"/></svg>
              戻る
            </a>
          </div>

          <button className={styles.nextBtn}  disabled={count === 0 && count2 === 0} onClick={sendData}>
            お客様情報登録へ
            <svg xmlns="http://www.w3.org/2000/svg"  height="30px" viewBox="0 0 30 30" width="30px" ><rect fill="none" height="30" width="30"/><path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z"/></svg>
          </button>
        </div>
      </div>
    </Layout>
  )
}
